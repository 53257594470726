<template>
  <Content>
    <template #contentTitle>
      {{ $t('finance.month_bill') }}
    </template>
    <template v-slot:contentBody="wrap">
      <a-table :columns="columns"
               :data-source="list"
               :scroll="{ x: 500,y: wrap.contentHeight - 100 }"
               :pagination="false"
               :loading="loading"
               :rowKey="(record, index) => {return index;}"
               size="small">
        <template #timeCycle={record}>
          {{getFormatDate(record.cycleStartTime)}}
          {{$t('finance.to')}}
          {{moment(record.cycleEndTime).add(-1, 'days').format('YYYY-MM-DD')}}
        </template>
        <template #country={record}>
          {{getLanguageName({cnName:record.countryCnName,enName:record.countryEnName,})}}
        </template>
        <template #money={record}>

          {{record.currencyCode}}
          <!-- {{record.currencySymbol}}{{record.totalAmount}} -->
          {{formatCurrencyAmount(record.currencySymbol,record.totalAmount)}}
        </template>
        <template #createTime={record}>
          {{getFormatDate(record.generatedDate)}}
        </template>
        <template #operate={record}>
          <a-button type="ghost" @click="handleShowModal(record)">{{$t('finance.details')}}</a-button>
        </template>
      </a-table>

      <a-modal v-model:visible="billModal.visible"
               :maskClosable='false'
               width="700px"
               :title="$t('finance.bill') "
               :footer="null">
        <a-spin :spinning="billModal.loading">
          <div id="print-modal">
            <div>
              <a-row :gutter=[0,16]>
                <a-col :span="14">
                  {{ $t('finance.bill_number') }}:
                  {{billModal.billNo}}
                </a-col>
                <a-col :span="10">
                  {{$t('finance.create_time')}}:
                  {{getFormatDate(billModal.generatedDate)}}
                </a-col>
                <a-col :span="14">
                  {{$t('finance.business_belonging_cycle')}}(UTC+0):
                  {{getFormatDate(billModal.cycleStartTime)}}
                  {{$t('finance.to')}}
                  {{moment(billModal.cycleEndTime).add(-1, 'days').format('YYYY-MM-DD')}}
                </a-col>
                <a-col :span="10">
                  {{$t('finance.business_country')}}:
                  {{getLanguageName({cnName:billModal.countryCnName,enName:billModal.countryEnName,})}}
                </a-col>
              </a-row>
            </div>
            <div class="border-bottom mt-3"></div>
            <div v-for="(item,index) in billModal.monthAccountBillBusinessTypeDetails"
                 :key="index">
              <div class="mt-3">
                <a-row type="flex"
                       justify="space-between"
                       align="bottom">
                  <a-col><strong>
                      {{$t($enumLangkey('businessType',item.businessType))}}
                    </strong></a-col>
                  <a-col>
                    {{formatCurrencyAmount(item.currencySymbol,item.amount)}}
                    <!-- {{item.currencySymbol}}{{item.amount}} -->
                  </a-col>
                </a-row>
                <div class="border-bottom"></div>
                <div class="ml-3"
                     style="width:300px"
                     v-for="(fee,index) in  getFeeItem(item?.businessType)"
                     :key="index">
                  <a-row type="flex"
                         justify="space-between"
                         align="bottom">
                    <a-col> {{$t($enumLangkey('orderLogisticsFee',fee?.feeType))}}</a-col>
                    <a-col class="mr-3">
                      {{formatCurrencyAmount(fee?.currencySymbol,fee?.amount)}}
                    </a-col>
                  </a-row>
                  <div class="border-bottom"></div>
                </div>
              </div>
            </div>

            <div class="border-bottom mt-4"></div>
            <div class="mt-3"
                 v-if="billModal.currencySymbol">
              <a-row type="flex"
                     justify="end">
                <a-col>{{$t('common.total')}}
                  {{formatCurrencyAmount(billModal.currencySymbol??'',billModal.totalAmount)}}
                </a-col>
              </a-row>
            </div>
          </div>
          <a-row type="flex"
                 justify="center"
                 class="mt-3">
            <a-col>
              <a-button class="mr-3"
                        @click="handleDownloadBill()">{{$t('finance.download_bill_details')}}</a-button>
              <a-button type="primary"
                        @click="handlePrint">{{$t('finance.print_bill')}}</a-button>
            </a-col>
          </a-row>
        </a-spin>
      </a-modal>
    </template>
    <!-- 分页 -->
    <template #contentFooter>
      <a-row type="flex"
             justify="space-around"
             align="middle"
             class="mb-1">
        <a-col>
          <CPager @handlePage="handlePage"
                  :page-data="pageData"></CPager>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import {
  defineComponent, reactive, toRefs, onActivated, ref
} from 'vue';
import {
  Table, Select, Button, Row, Col, Input, Modal, Spin
} from 'ant-design-vue';
import CPager from "../components/CPager.vue";
import Content from '../components/Content.vue';
import { getList, getBillDetails, getPrintUrl, getDownloadBillUrl } from '../../api/modules/finance/bill.js';
import { useI18n } from "vue-i18n/index";
import { getName, roundNumber } from "@/utils/general";
import { useStore } from "vuex";
import moment from "moment-timezone";
import localPrint from "../../utils/localPrint";
import { downloadFile } from "../../utils/downloader";

export default defineComponent({
  name: "finance_bill",
  components: {
    ATable: Table,
    CPager,
    Content,
    AButton: Button,
    ARow: Row,
    ACol: Col,
    AInput: Input,
    ASelect: Select,
    ASelectOption: Select.Option,
    AModal: Modal,
    ASpin: Spin
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const { getters } = useStore();
    const billModalRef = ref();

    const data = reactive({
      list: [],
      loading: false,
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10
      },
    });

    const getBillModal = () => {
      return {
        billNo: null,
        generatedDate: null,
        cycleStartTime: null,
        cycleEndTime: null,
        countryCnName: null,
        countryEnName: null,
        monthAccountBillFeeDetails: [],
        monthAccountBillBusinessTypeDetails: [],
        totalAmount: null,
        currencySymbol: null,
      }
    }

    const billModal = reactive({
      visible: false,
      loading: false,
    })

    const columns = [
      {
        title: () => vueI18n.t('finance.serial_number'),
        dataIndex: 'serialNumber',
        width: 50,
        customRender: ({ text, index }) => {
          return index + 1
        }
      },
      {
        title: () => vueI18n.t('finance.bill_number'),
        dataIndex: 'billNo',
      },
      {
        title: () => vueI18n.t('finance.business_belonging_cycle') + '(UTC+0)',
        dataIndex: 'timeCycle',
        slots: {
          customRender: "timeCycle",
        },
      },
      {
        title: () => vueI18n.t('finance.business_country'),
        dataIndex: 'country',
        slots: {
          customRender: "country",
        },
      },
      {
        title: () => vueI18n.t('finance.money'),
        dataIndex: 'money',
        slots: {
          customRender: "money",
        },
      },
      {
        title: () => vueI18n.t('finance.create_time'),
        dataIndex: 'createTime',
        slots: {
          customRender: "createTime",
        },
      },
      {
        dataIndex: 'operate',
        slots: {
          customRender: "operate",
        },
      },
    ];

    const handlePage = async (pageData) => {
      data.pageData.skipCount = pageData.skipCount;
      data.pageData.maxResultCount = pageData.maxResultCount;
      getData();
    }

    const handleShowModal = (record) => {
      billModal.visible = true;
      billModal.loading = true;
      Object.assign(billModal, getBillModal());
      getBillDetails(record.id).then((res) => {
        if (res.result) {
          //console.log(res.result);
          Object.assign(billModal, res.result);
        }
        billModal.loading = false;
      }).catch(() => {
        billModal.loading = false;
      })
    }

    const getLanguageName = (item) => {
      return getName(item, getters.language);
    }

    const getData = () => {
      data.loading = true;
      getList(data.pageData).then((res) => {
        if (res.result) {
          let { items, totalCount } = res.result;
          data.list = items;
          data.pageData.totalCount = parseInt(totalCount);
        } else {
          data.pageData.totalCount = 0;
        }
        data.loading = false;
      }).catch(() => {
        data.loading = false;
      });
    }

    const handleSearch = async () => {
      data.pageData.skipCount = 0;
      data.pageData.currentIndex = 1;
      getData()
    };

    const getFormatDate = (dateTime) => {
      return dateTime ? moment(dateTime).format('YYYY-MM-DD') : '-';
    }

    const getFeeItem = (businessType) => {
      try {
        let temp = [];
        temp = billModal.monthAccountBillFeeDetails
          .filter(x => x.businessType == businessType)
        return (temp && temp?.length > 0) ? temp : []
      } catch (error) {
        return [];
      }
    }

    const handlePrint = () => {
      billModal.loading = true;
      getPrintUrl(billModal.id).then((res) => {
        let { result } = res;
        if (result) {
          localPrint(result).catch((res) => {
            billModal.loading = false;
          })
        }
        billModal.loading = false;
      }).catch(() => {
        billModal.loading = false;
      })
    }

    const handleDownloadBill = () => {
      var url = getDownloadBillUrl(billModal.id);
      downloadFile(url, `bill_${billModal.billNo}.csv`);
    }

    //账单这里的:后端传整数,显示负数,仅仅是账单
    const formatCurrencyAmount = (symbol, amount) => {
      if (amount == 0) {
        return symbol + roundNumber(amount);
      } else if (amount > 0) {
        return "-" + symbol + roundNumber(amount);
      } else {
        return "+" + symbol + roundNumber(Math.abs(amount));
      }
    };

    onActivated(() => {
      handleSearch();
    });

    return {
      columns,
      billModal,
      billModalRef,
      ...toRefs(data),
      handleSearch,
      handlePage,
      handleShowModal,
      getLanguageName,
      moment,
      getFormatDate,
      getFeeItem,
      handlePrint,
      handleDownloadBill,
      formatCurrencyAmount
    };
  },
});
</script>
<style lang="less" scoped>
.border-bottom {
  border-bottom: 1px solid #f0f0f0;
}
</style>
